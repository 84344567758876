import React from 'react';
// Components
import MetasearcherAdvanced from '../MetasearcherAdvanced';
// Hooks
import { usePatents } from '../../hooks/usePatents';
import { useBooks } from '../../hooks/useBooks';
import { useLibrary } from '../../hooks/useLibrary';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
// Slices
import {
  setTotalNewsQuery,
  setIsNewsLoading,
  setIsNewsDataFetched,
  setResetNewsQuery,
  setNews,
  setQueryNewsView,
} from '../../app/features/newsSlice';
import {
  setWord,
  setWordFetched,
  setWordFetchedAdvanced,
  setLoadingMetasearch,
} from '../../app/features/metasearchSlice';
import {
  setMultimedia,
  setIsMultimediaLoading,
  setTotalMultimedia,
  resetMultimedia,
  setIsMediaDataFetched,
} from '../../app/features/multimediaSlice';
import {
  setIsDictionaryDataFetched,
  setElementaryData,
  setIsElementaryDataLoading,
  setMedicalData,
  setIsMedicalDataLoading,
  setThesaurusData,
  setIsThesaurusDataLoading,
  resetDictionaty,
} from '../../app/features/dictionarySlice';
// Services
import { getMultimedia } from '../../services/getMultimedia';
import { getNewsFromQuery } from '../../services/getNewsFromQuery';
import { sendUserInformation } from '../../services/sendUserInformation';
import {
  getElementaryDictionary,
  getMedicalDictionary,
  getThesaurusDictionary,
} from '../../services/getDictionary';
// Icons
import { IoMdSearch } from 'react-icons/io';
// Style
import './Metasearcher.css';

const Metasearcher = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { pathname } = useLocation();
  const currentlyRoute = pathname.replace('/', '');

  // Hooks
  const { getLibraryDataSocket } = useLibrary();
  const { getPatentsDataSocket } = usePatents();
  const { getBooksDataSocket } = useBooks();
  // Slices
  const { user_email } = useSelector((state) => state.user);
  const { searchType, allLibrarySources } = useSelector(
    (state) => state.library
  );
  const { allBooksSources } = useSelector((state) => state.books);
  const { allPatentsSources } = useSelector((state) => state.patents);
  const { institutionCode } = useSelector((state) => state.institution);
  const { word, resourceType } = useSelector((state) => state.metasearch);
  const { isMedicalInstitution } = useSelector((state) => state.institution);

  const requestData = () => {
    // Envio de datos de busqueda y de usuario
    sendUserInformation(
      currentlyRoute === 'search' ? 'library' : resourceType,
      word,
      user_email,
      institutionCode
    );
    // Activa loading del Metabuscador
    dispatch(setLoadingMetasearch(true));
    searchType !== 'simple'
      ? dispatch(setWordFetchedAdvanced(word))
      : dispatch(setWordFetched(word));
    // Library
    getLibraryDataSocket(
      word,
      1,
      false,
      1990,
      2024,
      allLibrarySources,
      [],
      searchType,
      false
    );

    if (searchType === 'simple' || currentlyRoute !== 'library') {
      // Multimedia
      dispatch(resetMultimedia());
      dispatch(setIsMultimediaLoading(true));
      getMultimedia(word, 1)
        .then((response) => {
          if (response.status === 200) {
            dispatch(setIsMediaDataFetched(true));
            dispatch(setMultimedia(response?.data?.data));
            dispatch(setTotalMultimedia(response?.data?.count));
          }
          dispatch(setIsMultimediaLoading(false));
        })
        .catch((error) => {
          console.error('Request error:', error);
          dispatch(setMultimedia([]));
          dispatch(setTotalMultimedia(0));
          dispatch(setIsMultimediaLoading(false));
        });

      // News
      dispatch(setResetNewsQuery());
      dispatch(setQueryNewsView(true));
      dispatch(setIsNewsLoading(true));
      getNewsFromQuery(word, 1)
        .then((response) => {
          if (response.status === 200) {
            dispatch(setIsNewsDataFetched(true));
            dispatch(setNews(response?.data?.data));
            dispatch(setTotalNewsQuery(response?.data?.count));
          }
          dispatch(setIsNewsLoading(false));
        })
        .catch((error) => {
          console.error(error);
          dispatch(setNews([]));
          dispatch(setTotalNewsQuery(0));
          dispatch(setIsNewsLoading(false));
        });

      // Dictionary
      dispatch(resetDictionaty());
      if (isMedicalInstitution) {
        dispatch(setIsMedicalDataLoading(true));
        getMedicalDictionary(word)
          .then((response) => {
            if (typeof response.data[0] !== 'string') {
              let dataMedical = [];
              response.data.map((element) => {
                dataMedical.push({
                  meta: element.meta,
                  shortdef: element.shortdef,
                  fl: element.fl,
                  def: element.def,
                });
                return null;
              });
              dispatch(setMedicalData(dataMedical));
            } else {
              dispatch(setMedicalData(response?.data));
            }
            dispatch(setIsMedicalDataLoading(false));
            dispatch(setIsDictionaryDataFetched(true));
          })
          .catch((error) => {
            dispatch(setIsMedicalDataLoading(false));
            console.error('Request error:', error);
          });
      }
      dispatch(setIsThesaurusDataLoading(true));
      getThesaurusDictionary(word)
        .then((response) => {
          if (typeof response.data[0] !== 'string') {
            let dataThesaurus = [];
            response.data.map((element) => {
              dataThesaurus.push({
                meta: element.meta,
                shortdef: element.shortdef,
                fl: element.fl,
                def: element.def,
              });
              return null;
            });
            dispatch(setThesaurusData(dataThesaurus));
          } else {
            dispatch(setThesaurusData(response?.data));
          }
          dispatch(setIsThesaurusDataLoading(false));
          dispatch(setIsDictionaryDataFetched(true));
        })
        .catch((error) => {
          console.error('Request error:', error);
          dispatch(setIsThesaurusDataLoading(false));
        });
      dispatch(setIsElementaryDataLoading(true));
      getElementaryDictionary(word)
        .then((response) => {
          if (typeof response.data[0] !== 'string') {
            let dataElementary = [];
            response.data.map((element) => {
              dataElementary.push({
                meta: element.meta,
                shortdef: element.shortdef,
                history: element.history,
                fl: element.fl,
                def: element.def,
              });
              return null;
            });
            dispatch(setElementaryData(dataElementary));
          } else {
            dispatch(setElementaryData(response?.data));
          }
          dispatch(setIsElementaryDataLoading(false));
          dispatch(setIsDictionaryDataFetched(true));
        })
        .catch((error) => {
          console.error('Request error:', error);
          dispatch(setIsElementaryDataLoading(false));
        });

      // Patents
      getPatentsDataSocket(word, 1, 1990, 2024, allPatentsSources);

      // Books
      getBooksDataSocket(
        word,
        1,
        1990,
        2024,
        allBooksSources,
        [],
        '',
        '',
        'en'
      );
    }
  };

  const getDataMetasearcher = async () => {
    if (word?.length > 0) {
      requestData();
      currentlyRoute === 'search' && navigate('/articles');
    }
  };

  const handleMetasearcher = (e) => {
    const keyCode = e.keyCode;
    if (keyCode === 13) {
      getDataMetasearcher();
    }
  };

  return (
    <div className='metasearchBarContainer'>
      <input
        placeholder='Do a search...'
        onKeyDown={handleMetasearcher}
        className='metasearchBar'
        type='text'
        value={word}
        onChange={(e) => dispatch(setWord(e.target.value))}
      ></input>
      <div className='metasearchOptionsContainer'>
        <MetasearcherAdvanced route={currentlyRoute} />
      </div>

      <button
        onClick={(e) => {
          e.preventDefault();
          getDataMetasearcher(searchType);
        }}
        className='metasearchButton'
        type='submit'
      >
        <p>Search</p>
        <IoMdSearch />
      </button>
    </div>
  );
};

export default Metasearcher;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
// Components
import Container from '../../Components/Container';
import Footer from '../../Components/Footer';
// Slices
import {
  setUserEmail,
  setUserName,
  setUserLastname,
  setUserCode,
  setActiveLoginModal
} from '../../app/features/userSlice';
// Services
import { getInfoUserForm } from '../../services/getInfoUserForm';
import { getInfoUserTalent } from '../../services/getInfoUserTalent';
// Images
import aquinoLogin from '../../Images/Login/LoginPage/aquinoLogin.svg';
// Icons
import { HiOutlineUser } from 'react-icons/hi';
import { RiLockPasswordLine } from 'react-icons/ri';
import { IoChevronBackOutline } from 'react-icons/io5';
// Styles
import './LoginPage.css';

const LoginPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { institutionCode } = useSelector((state) => state.institution);

  // Variables
  const [passForm, setPassForm] = useState('');
  const [emailForm, setEmailForm] = useState('');
  const [usernameForm, setUsernameForm] = useState('');
  const [errorInLogin, setErrorInLogin] = useState(false);

  const getDataTalent = (user, email) => {
    getInfoUserTalent()
      .then((response) => {
        const userSearched = {
          login: user,
          email: email,
        };
        for (const user of response.data) {
          if (
            user.email === userSearched.email &&
            user.login === userSearched.login
          ) {
            dispatch(setUserEmail(user.email));
            dispatch(setUserName(user.first_name));
            dispatch(setUserLastname(user.last_name));
            dispatch(setUserCode(user.login_key));
            navigate(`/`);
            return null;
          }
        }
        showError();
      })
      .catch((error) => {
        console.error('Request error:', error);
      });
  };

  const getDataUserForm = (user, pass) => {
    getInfoUserForm(user, pass)
      .then((response) => {
        if (response.status === 200) {
          dispatch(setUserEmail(response.data.username));
          dispatch(setUserName(response.data.username));
          dispatch(setActiveLoginModal(false));
          navigate(`/search`);
        } else {
          showError();
        }
      })
      .catch((error) => {
        console.error('Request error:', error);
      });
  };

  const showError = () => {
    setErrorInLogin(true);
    setTimeout(() => {
      setErrorInLogin(false);
    }, '6000');
  };

  return (
    <main className='loginPage'>
      <Container className='loginPageContainer'>
        <button className='backLoginButton' onClick={() => navigate('/')}>
          <IoChevronBackOutline />
          Return
        </button>
        <div className='loginContainer'>
          <img src={aquinoLogin} alt='Aquino' />
          <p className='info'>
            Welcome to <b>Aquinas Online Library</b>
            <br /> <b>Log in</b> to your account and{' '}
            <b>explore our resources</b>
          </p>
          <form
            className='formLogin'
            onSubmit={(e) => {
              e.preventDefault();
              institutionCode === 'MIAMI_BROWARD'
                ? getDataTalent(usernameForm, emailForm)
                : getDataUserForm(usernameForm, passForm);
            }}
          >
            <div className='formLogin__inputs'>
              <div>
                <div className='input-container'>
                  <label>User:</label>
                  <input
                    onChange={(e) => setUsernameForm(e.target.value)}
                    type='text'
                    name='name'
                    placeholder='email or username'
                    required
                  />
                  <HiOutlineUser />
                </div>
              </div>
              <div>
                <div className='input-container'>
                  <label>
                    {institutionCode === 'MIAMI_BROWARD'
                      ? 'Email:'
                      : 'Password:'}
                  </label>
                  <input
                    onChange={(e) => {
                      institutionCode === 'MIAMI_BROWARD'
                        ? setEmailForm(e.target.value)
                        : setPassForm(e.target.value);
                    }}
                    type={
                      institutionCode === 'MIAMI_BROWARD' ? 'email' : 'password'
                    }
                    name='name'
                    placeholder={
                      institutionCode === 'MIAMI_BROWARD' ? 'email' : 'password'
                    }
                    required
                  />
                  <RiLockPasswordLine />
                </div>
              </div>
            </div>
            <button className='btnLogin' type='submit'>
              Login
            </button>
          </form>
          {errorInLogin && (
            <p className='errorMessage'>Email or username are incorrect.</p>
          )}
        </div>
      </Container>
      <Footer allContent={false} />
    </main>
  );
};

export default LoginPage;
